import React, {Component} from 'react'
import "./components.css";
import ReactPlayer from 'react-player'
import Duration from './Duration'

class VideoComponent extends Component {
    state = {
        // url: null,
        pip: false,
        playing: true,
        controls: false,
        light: false,
        volume: 0.8,
        muted: true,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: false,
        progress: 0
    }

    interval = null

    load = url => {
        this.setState({
            url,
            played: 0,
            loaded: 0,
            pip: false
        })
    }
    handleOnPlaybackRateChange = (speed) => {
        this.setState({ playbackRate: parseFloat(speed) })
    }

    handlePlay = () => {
        if (!this.interval) {
            this.interval = setInterval(() => {
                this.setState((prevState) => ({
                    progress: prevState.progress > 0 ? prevState.progress - 0.005 : 0,
                }));
            }, 5);
        }

        this.setState({ playing: true });
    }


    handlePause = () => {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
        this.setState({ playing: false });
    }

    handleProgress = state => {
        // console.log('onProgress', state)
        if (!this.state.seeking) {
            this.setState(state)
        }
    }

    handleEnded = () => {
        // console.log('onEnded')
        this.setState({ progress: this.state.duration });
        this.props.changeOrderNumber(this.props.index + 1)
        this.setState({ playing: this.state.loop })
    }

    handleDuration = (duration) => {
        // console.log('onDuration', duration)
        this.setState({ duration })
        this.setState({ progress: duration})
    }

    handleOnStart = () => {
        // console.log('onStart')
        this.interval = setInterval(() => {
            this.setState((prevState) => ({
                progress: prevState.progress > 0 ? prevState.progress - 0.005 : 0,
            }));
        }, 5);
    }

    ref = player => {
        this.player = player
    }

    render () {
        const { progress, playing, controls, light, volume, muted, loop, played, loaded, duration, playbackRate, pip } = this.state
        const { url, index, play, changeOrderNumber} = this.props;

        return (
            <div
                className='player-wrapper'
                // onMouseEnter={() => changeOrderNumber(index)}
                style={{paddingLeft: 10}}
            >
                <progress
                    className=" progressBar"
                    max={duration}
                    value={progress}
                    style={{width: 100 + "%", borderTopRightRadius: 16, borderTopLeftRadius: 16, overflow: "hidden"}}
                />
                <ReactPlayer
                    ref={this.ref}
                    className='react-player'
                    width = "100%"
                    height= "auto"
                    url={url}
                    pip={pip}
                    playing={play}
                    playsinline={true}
                    controls={controls}
                    light={light}
                    loop={loop}
                    playbackRate={playbackRate}
                    volume={volume}
                    muted={muted}
                    // onReady={() => console.log('onReady')}
                    onStart={this.handleOnStart}
                    onPlay={this.handlePlay}
                    onPause={this.handlePause}
                    // onBuffer={() => console.log('onBuffer')}
                    onPlaybackRateChange={this.handleOnPlaybackRateChange}
                    // onSeek={e => console.log('onSeek', e)}
                    onEnded={this.handleEnded}
                    // onError={e => console.log('onError', e)}
                    onProgress={this.handleProgress}
                    onDuration={this.handleDuration}
                    // onPlaybackQualityChange={e => console.log('onPlaybackQualityChange', e)}
                />
            </div>
        )
    }
}

export default VideoComponent