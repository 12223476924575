import React, {createContext, useContext, useEffect, useState} from 'react';
import LoadingComponent from "../components/LoadingComponent";


const LanguageContext = createContext(null);

export const LanguageProvider = ({ children }) => {
    const languageStrings = {
        ENG: {
            addFrame: 'Add a frame',
            frameTypes: [
              "White frame", "Wood frame", "Black frame"
            ],
            price: 'price',
            product: 'product',
            telegram: "@kristina_andrus_art",
            instagram: "@kristina_andrus_art",
            email: "ksartabstract@gmail.com",
            getintouch: "GET IN TOUCH",
            commissionWork: "COMMISSION WORK",
            commissionWorkDescription: "If you would like to acquire any of my artworks but it's already sold or you are looking to get something a little more personalised - feel free to contact me"
                + "at **ksartabstract@gmail.com** or send me direct message hier",
            nameInput: "NAME",
            emailInput: "EMAIL",
            phoneInput: "PHONE",
            commentInput: "COMMENT",
            send: "SEND",
            moreArt: "MORE ART",
            storeTitle: "STORE",
            aboutMeText: "Kristina Andrus is a self-taught artist from Germany with Ukrainian roots. The beauty of nature and its fascinating structures, alongside her interest in the deeper layers of life, serve as her main sources of inspiration. Each artwork holds its own philosophy and significance.  \n" +
                "\n" +
                "Her style combines minimalism, abstraction, and contemporary art, seamlessly fitting into any interior while emanating a distinct aesthetic.  \n" +
                "\n" +
                "She is passionate about creating unique 3D plaster wall art, textures on canvases made of high-quality cotton or linen, and abstract acrylic paintings. Kristina’s motto ‘DU BIST ART’ resonates with every individual on this planet and is reflected in her artworks, which are 100% unique and carry their own philosophy and meaning. With numerous planned projects and goals, her keen sense of beauty consistently inspires her and supports her creative potential.",
            cartIsEmpty: "CART IS NOW EMPTY",
            warnCart: "ALL ORDERS ARE PROCESSED IN EUR, USING THE LATEST EXCHANGE RATES",
            textAreaPlaceHolderCart: "How can we help you?",
            addOrderNote: "Add order note",
            total: "Total: ",
            followUs: "Follow us on Instagram",
            cartNotice1: "International shipments may be subject to duties, taxes, and other fees that are not included in the total price",
            cartNotice2: "As a small business owner according to § 19 Abs. 1 UStG, no sales tax is charged",
            privacyPolicyTitle: "PRIVACY POLICY",
            privacyPolicy:"##### **Privacy policy of ksartbykristinaandrus.de** \n\n" +
                "For information about the personal data, the purpose and the parties to which this data is shared, contact the owner \n\n" +
                "##### **Provider and responsible person** \n\n" +
                "Kristina Andrus \n\n" +
                "An der Untertrave 54 \n\n" +
                "23552 Lübeck \n\n" +
                "**Provider email address:** ksartabstract@gmail.com \n\n" +
                "##### **Types of data collected** \n\n" +
                "The Owner does not provide a list of the personal data collected.\n\n" +
                "Full details of each type of personal data processed are provided in the dedicated sections of this privacy policy or selectively through explanatory texts displayed before data collection. \n\n" +
                "Personal Data may be provided voluntarily by the User or, in the case of Usage Data, collected automatically when using this Application. \n\n" +
                "Unless otherwise stated, the provision of all data requested by this Application is mandatory. If the User refuses to provide the Data, this may result in this Application not being able to provide its services to the User. In cases where these If the provision of personal data is expressly stated to be voluntary, users may choose not to provide this data without any consequences for the availability or functionality of the service. \n\n" +
                "Users who are unsure about which personal data is mandatory can contact the provider. \n\n" +
                "Any use of cookies - or other tracking tools - by this Application or the providers of third-party services used through this Application serves the purpose of providing the service requested by the User and all other purposes specified in this document and in the Cookie Policy are described. \n\n" +
                "Users are responsible for all third party personal data obtained, published or shared through this Application.\n\n" +
                "##### **Type and location of data processing** \n\n" +
                "**Processing methods** \n" +
                "The provider processes user data in a proper manner and takes appropriate security measures to prevent unauthorized access and unauthorized transfer, alteration or destruction of data. \n\n" +
                "Data processing is carried out using computers or IT-based systems according to organizational procedures and procedures that are specifically aimed at the stated purposes. In addition to the controller, other people could also be internal (human resources, sales, marketing, legal department, system administrators) or external - and in In the case where necessary, designated by the person responsible as a processor (such as providers of technical services, delivery companies, hosting providers, IT companies or communications agencies) - operate this application and thus have access to the data. A current list of these parties involved can be requested from the provider at any time be. \n\n" +
                "**Location** \n" +
                "The data will be processed at the provider's branch and at all other locations where the entities involved in data processing are located.\n\n" +
                "Depending on the location of the users, data transfers may involve the transfer of the user's data to a country other than their own. To find out more about the place of processing of the transferred data, users can consult the section detailing the processing of personal data consult. \n\n" +
                "**Storage duration** \n" +
                "Unless otherwise specified in this document, personal data will be processed and stored for as long as required by the purpose for which they were collected and, if necessary, may be retained for longer due to a legal obligation to be fulfilled or based on the user's consent be. \n\n" +
                "##### **Cookie Policy** \n\n" +
                "This application uses trackers. Further information can be found in the Cookie Policy. \n\n" +
                "##### **More information for users** \n\n" +
                "**Legal basis for processing** \n" +
                "The provider may only process users' personal data if one of the following points applies: \n\n" +
                "• Users have given their consent for one or more specific purposes. Note: In some legislations, the provider may be allowed to process personal data until the user objects to such processing (“opt-out”), without having to consent or another of the following legal bases. However, this does not apply if the processing of personal data is subject to European data protection law; \n\n" +
                "• the data collection is necessary for the fulfillment of a contract with the user and/or for pre-contractual measures therefrom; \n\n" +
                "• the processing is necessary for the fulfillment of a legal obligation to which the provider is subject; \n\n" +
                "• the processing is related to a task carried out in the public interest or in the exercise of public authority vested in the provider; \n\n" +
                "• the processing is necessary to protect the legitimate interests of the provider or a third party.\n\n" +
                "In any case, the provider will be happy to provide information about the specific legal basis on which the processing is based, in particular whether the provision of personal data is a legal or contractual obligation or a prerequisite for concluding a contract. \n\n" +
                "**More information about the length of stay** \n\n" +
                "Unless otherwise specified in this document, personal data will be processed and stored for as long as required by the purpose for which they were collected and, if necessary, may be retained for longer due to a legal obligation to be fulfilled or based on the user's consent be. \n\n" +
                "Therefore: \n" +
                "• Personal data collected for the purpose of fulfilling a contract concluded between the provider and the user will be stored until this contract has been fully fulfilled. \n\n" +
                "• Personal data collected to protect the Provider's legitimate interests will be retained for as long as necessary to fulfill these purposes. Users can obtain more detailed information about the Provider's legitimate interests in the relevant sections of this document or by contacting Provider received. \n\n" +
                "In addition, the provider is permitted to store personal data for a longer period of time if the user has consented to such processing, as long as the consent is not withdrawn. In addition, the provider may be obliged to retain personal data for a longer period of time if this is necessary to fulfill a legal obligation or by order of an authority. \n\n" +
                "After the retention period has expired, personal data will be deleted. Therefore, the right of access, the right to deletion, the right to rectification and the right to data portability cannot be asserted after the retention period has expired. \n\n" +
                "**The rights of users** \n" +
                "Users may exercise certain rights regarding their data processed by the provider. \n\n" +
                "Users have, to the extent permitted by law, the right in particular to do the following: \n" +
                "• **Revoke consent at any time**. If the user has previously consented to the processing of personal data, they can revoke their own consent at any time. \n\n" +
                "• **Object to the processing of their data**. The user has the right to object to the processing of their data if the processing is carried out on a legal basis other than consent. \n\n" +
                "• **Receive information regarding their data**. The user has the right to know whether the data is being processed by the provider, to receive information about individual aspects of the processing and to receive a copy of the data. \n\n" +
                "• **Have it checked and corrected**. The user has the right to check the accuracy of his data and to request that it be updated or corrected. \n\n" +
                "• **Request restriction of the processing of their data**. Users have the right to restrict the processing of their data. In this case, the provider will not process the data for any purpose other than storage. \n\n" +
                "• **Request deletion or other removal of personal data**. Users have the right to request that the provider delete their data. \n\n" +
                "• **Receive your data and have it transferred to another person responsible**. The user has the right to receive his data in a structured, common and machine-readable format and, if technically possible, to have it transmitted to another person responsible without hindrance. \n\n" +
                "• **Submit a complaint**. Users have the right to lodge a complaint with the relevant supervisory authority. \n\n" +
                "Users also have the right to be informed about the legal basis for the transfer of data abroad or to an international organization governed by international law or established by two or more countries, such as the UN, as well as the security measures taken by the provider to protect it to have your data clarified. \n\n" +
                "**Details on the right to object to processing** \n\n" +
                "**If personal data are processed in the public interest, in the exercise of a sovereign authority vested in the provider or to safeguard the legitimate interests of the provider, the user may object to this processing by providing a justification that relates to his or her particular situation.* * \n\n" +
                "**Users are informed that they can object to the processing of personal data for direct advertising at any time and free of charge without giving reasons. If the user objects to the processing for direct advertising purposes, the personal data will no longer be processed for these purposes. Whether the Users can find out whether providers process personal data for direct marketing purposes in the relevant sections of this document.** \n\n" +
                "**How the rights can be exercised** \n\n" +
                "All requests to exercise user rights can be directed to the provider using the contact details provided in this document. Requests can be made free of charge and will be answered by the provider as early as possible, no later than within one month, and will provide users with the information required by law Any correction or deletion of personal data or restriction of processing will be communicated by the provider to all recipients to whom personal data has been disclosed, if any. Unless this proves impossible or involves disproportionate effort. The The provider informs the user about these recipients if the user requests this. \n\n" +
                "##### **Further information about the collection and processing of data** \n\n" +
                "**Legal measures** \n" +
                "The User's personal data may be processed by the Provider for legal enforcement purposes within or in preparation for legal proceedings resulting from improper use of this Application or the associated services. \n\n" +
                "The user declares that he is aware that the provider could be obliged by the authorities to release personal data. \n\n" +
                "**More information about the user's personal data** \n" +
                "In addition to the information set out in this Privacy Policy, this Application may, upon request, provide the User with other contextual information relating to specific Services or to the collection and processing of Personal Data.\n\n" +
                "**System logs and maintenance** \n" +
                "This Application and third-party services may collect files that record interaction with this Application (System logs) or use other Personal Data (such as IP Address) for this purpose for operation and maintenance purposes. \n\ n" +
                "**Information not contained in this privacy policy** \n" +
                "Further information about the collection or processing of personal data can be requested from the provider at any time using the contact details listed. \n\n" +
                "**Changes to this Privacy Policy** \n" +
                "The provider reserves the right to make changes to this data protection declaration at any time by informing users on this page and, if necessary, via this application and/or - where technically and legally possible - by sending a message using the user's contact details available to the provider. Users We therefore recommend that you visit this page regularly and in particular check the date of the last change stated at the bottom of the page. \n\n" +
                "If changes affect data use based on the user's consent, the provider will - if necessary - obtain new consent.",
            termOfServiceTitle: "TERM OF SERVICE",
            termOfService: "**Our Privacy Policy sets out how we’ll use your information.**  \n\n" +
                "##### **Order Placement & Product Price Indication** \n\n" +
                "All orders are subject to availability and confirmation of the order price. If there’s an issue with an order, we’ll get in touch with you.  \n\n" +
                "The main characteristics of the products are shown on each product page on ksartbykristinaandrus.de. The images and the products offered for sale on ksartbykristinaandrus.de may not correspond exactly to the true physical product due to the Internet browser, smart phone or monitor used by the customer.  \n\n" +
                "From time to time, we may price an item or order wrongly. If we discover an error for any goods you’ve ordered, we will tell you ASAP and give you the option of reconfirming your order (at the correct price) or cancelling it.  \n\n" +
                "From time to time, prices are subject to change in response to currency exchange rate changes and other commercial factors.   \n\n" +
                "We reserve the right to reject or cancel any orders which do not comply with these terms even if your credit or debit card has been charged (and will refund in full any amounts paid by you in respect of the order).  \n\n" +
                "##### **Intellectual Property, Content & Software**  \n\n" +
                "KristinaAndrus owns or has permission to use the intellectual property rights on its website and its content. These rights are protected around the world. All rights are reserved.  \n\n" +
                "You’re allowed to store, print and display our website content only for your own personal use. You are not allowed to use any part of the website or it’s content for commercial purposes unless you have our express permission.  \n\n" +
                "You’re also not permitted to use the KSArt logo without our express permission.  \n\n" +
                "It’s fine for you to link to ksartbykristinaandrus.de, as long as you do so in a way that is not-commercial, is fair and legal, and doesn’t damage or take advantage of our reputation.  \n\n" +
                "Our website must not be framed on any other site without our permission.  \n\n" +
                "##### **Kristina Andrus Art Role & Responsibilities**  \n\n" +
                "When you complete a purchase on ksartbykristinaandrus.de, you enter a contract with Kristina Andrus which supplies the product. We provide a platform which we trade our products to you, the consumer. We complete a sale and process payments, refunds & shipping. Once we receive your full payment for an order, we will remove your debt obligation to Kristina Andrus Art for that order.  \n\n" +
                "Shipping is handled by Kristina Andrus through DHL or another supplier.  \n\n" +
                "##### **Ordering Conditions**  \n\n" +
                "By clicking on the ‘Confirm and Pay\"’button, you enter into an obligation to pay for the Product(s).  \n\n" +
                "After your order has been placed, we will process your order. You will receive an automatic order confirmation notification from us. This does not constitute acceptance of your order by KristinaAndrus nor does a contract between Kristina Andrus and the customer exist at this point in time.    \n\n" +
                "Kristina Andrus acceptance of your order will take place when the we email you to confirm that the order has been dispatched. A contract will come into existence between you and Kristina Andrus. The acceptance email represents an electronic purchase order receipt.   \n\n" +
                "Kristina Andrus are not obliged to fulfill an initial order and can reject an order at our or their discretion.  \n\n" +
                "##### **Advance Payment and Refund Policy for Custom Orders**  \n\n" +
                "For all custom orders a 50% advance payment of the total price is required. This advance payment is intended to cover the associated costs and serves as security for the execution of the order.  \n\n" +
                "Please note that the advance payment is non-refundable. This means that in the event of order cancellation by the customer or for reasons beyond our control, the paid advance will not be refunded.  \n\n" +
                "The remaining 50% of the total price is due upon completion of the work and before the delivery of the final products or services. Only after full payment will the final products or services be handed over to the customer.  \n\n" +
                "We reserve the right to cancel the order if the advance payment or the remaining amount is not paid in a timely manner.  \n\n",
            cookiePolicyTitle: "COOKIE POLICY",
            cookiePolicy: "##### **Cookie policy of ksartbykristinaandrus.de** \n\n"
                + "This document informs users about the technologies that this Application uses to achieve the purposes described below. These technologies allow access to or storage of information (e.g. through the use of cookies) on the user's device or the use of the data processing capabilities of the device when the user accesses or runs this application." +
                "For the sake of simplicity, unless there is reason to differentiate, such technologies are defined as 'trackers' within this document. For example, although cookies can be used in both web and mobile browsers, they are not used as browser-based staplers in mobile apps in question. Therefore, the term cookie is only used in this document if the type of tracker is specifically meant." +
                "Some of the processing purposes for which trackers are used require the user's consent. If consent is given, it can be freely withdrawn at any time following the procedure described in this document." +
                "This application only uses the provider's own trackers (so-called 'first-party trackers'). The period of validity and expiry of first-party cookies and other comparable trackers depend on the lifespan set by the provider. \n\n" +
                "**Operations strictly necessary to operate this application and provide the service** \n\n" +
                "This application uses so-called “technical” cookies and similar trackers to enable operations that are strictly necessary for the operation and provision of the service. \n\n" +
                "##### **Manage settings and grant or revoke consent** \n\n" +
                "Managing tracker settings and granting and revoking consent can be done in different ways:" +
                "Users can manage tracker settings directly via their own device settings, for example by completely objecting to the use or storage of trackers. \n\n" +
                "To the extent that the use of trackers is based on consent, users can also grant or revoke consent by making the appropriate selection in the cookie notice or by changing the specified selection via the corresponding consent widget for data protection preferences (if available)." +
                "Already saved trackers, including those responsible for storing the user's original consent preferences, can also be deleted via the appropriate browser or device settings. \n\n" +
                "Other trackers stored in the browser's local storage can be removed by clearing the history. \n\n" +
                "##### **Manage tracker settings** \n\n" +
                "Users can, for example, find information about managing cookies via the most common browsers at the following addresses: \n\n" +
                "* [Google Chrome](https://support.google.com/chrome/answer/95647?hl=de&p=cpn_cookies) \n\n" +
                "* [Mozilla Firefox](https://support.mozilla.org/de/kb/verbesserter-schutz-aktivitatentracking-desktop?redirectslug=cookies-erlauben-und-ablehne&redirectlocale=de) \n\n" +
                "* [Apple Safari](https://support.apple.com/de-de/guide/safari/sfri11471/mac) \n\n" +
                "* [Microsoft Internet Explorer](https://support.microsoft.com/en-gb/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d) \n\n" +
                "* [Microsoft Edge](https://support.microsoft.com/de-de/microsoft-edge/cookies-in-microsoft-edge-Löschen-63947406-40ac-c3b8-57b9-2a946a29ae09) \n\n" +
                "* [Brave](https://support.brave.com/hc/en-us/articles/360022806212-How-do-I-use-Shields-while-browsing) \n\n" +
                "* [Opera](https://help.opera.com/de/latest/web-preferences/#cookies) \n\n" +
                "Users can also manage certain trackers used within mobile apps via appropriate device settings - for example via the device settings for advertising on mobile devices, or generally via tracking settings (users can go to device settings and search for the corresponding commands). \n\n" +
                "##### **Consequences of refusing to use trackers** \n\n" +
                "Users are free to decide whether or not to give their consent to the use of trackers. However, it should be noted that trackers contribute to the ability of this Application to provide Users with a better user experience and enhanced functionality (in accordance with the provisions herein If the user decides to block the use of trackers, the provider may not be able to provide the corresponding functions. \n\n" +
                "**Provider and responsible person** \n\n" +
                "**Provider email address:** ksartabstract@gmail.com \n\n" +
                "Due to the objective complexity of tracking technologies, users are invited to contact the provider if they would like to receive further information about the use of such technologies through this application.\n\n",
            refundTitle: "REFUND",
            refund: "##### **Damage Reporting:**  \n\n Any damages to delivered artworks must be reported in writing via email within 3 days of receipt. The report should include a detailed description of the damage along with photos of the affected artwork.  \n\n" +
                "##### **Refund Claim:**  \n\n The customer is eligible for a refund only if the damage is reported within the aforementioned timeframe. Refund will be processed only upon the return of the damaged artwork.  \n\n" +
                "##### **Return and Shipping Costs:**  \n\n The buyer is responsible for the proper return of the damaged artwork. The shipping costs for the return are to be borne by the buyer.  \n\n" +
                "##### **Return Conditions:**  \n\n The artwork must be returned in its original condition and packaging. The return must be made within a reasonable period after receiving instructions for the return.  \n\n" +
                "##### **Refund:**  \n\n The refund will be processed upon receipt and inspection of the returned artwork. We reserve the right to deny a refund if the artwork does not meet the return conditions.",
            shippingPolicyTitle: "WORLDWIDE SHIPPING",
            shippingPolicy: "Delivery within the European Union countries is included in the price. Shipping takes place within a week.  \n\n" +
                "**Important information regarding delivery outside the European Union countries:**  \n\n" +
                "Tolls, taxes, and additional fees not included in the total price may apply to international shipments. Please contact me via email or Instagram to clarify the shipping cost. Thank you for your understanding  \n\n",
            commissionedWork: "Prices for commissioned work:\n" +
                "\n" +
                " • 130x100 cm - 690€ \n\n" +
                " • 100x100 cm - 560€ \n\n" +
                " • 80x100 cm - 490€"
        },
        DE: {
            addFrame: 'Einen Rahmen hinzufügen',
            frameTypes: [
                "Weißrahme", "Holzrahme", "Schwarzrahme"
            ],
            price: 'preis',
            product: 'produkt',
            telegram: "@kristina_andrus_art",
            instagram: "@kristina_andrus_art",
            email: "ksartabstract@gmail.com",
            getintouch: "GET IN TOUCH",
            commissionWork: "COMMISSION WORK",
            commissionWorkDescription: "Wenn Sie eines meiner Kunstwerke erwerben möchten, es aber bereits verkauft ist, oder wenn Sie etwas persönlicheres wünschen, können Sie mich gerne unter **ksartabstract@gmail.com** kontaktieren oder mir hier eine Direktnachricht senden",
            nameInput: "NAME",
            emailInput: "EMAIL",
            phoneInput: "PHONE",
            commentInput: "COMMENT",
            send: "SEND",
            moreArt: "MEHR ART",
            storeTitle: "STORE",
            aboutMeText: "Kristina Andrus ist eine selbstlernende Künstlerin aus Deutschland mit ukrainischen Wurzeln. Die Schönheit der Natur und die faszinierenden Strukturen, die sie hervorbringt, sowie ihr Interesse an den tieferen Schichten des Lebens sind ihre Hauptquellen der Inspiration. Jedes Kunstwerk hat seine eigene Philosophie und Bedeutung.  \n\n" +
                "Ihr Stil vereint Minimalismus, Abstraktion und zeitgenössische Kunst, die nahtlos in jede Inneneinrichtung passen und eine besondere Ästhetik ausstrahlen.  \n\n" +
                "Ihre Leidenschaft liegt in der Herstellung von einzigartiger 3D-Gips-Wandkunst, Texturen auf Leinwänden aus hochwertiger Baumwolle oder Leinen sowie abstrakter Acrylmalerei. Kristina’s Motto ‘DU BIST ART’ spricht jeden Menschen auf diesem Planeten an und spiegelt sich in ihren Kunstwerken wider, die 100%ige Unikate sind und ihre eigene Philosophie und Bedeutung haben. Sie hat viele geplante Projekte und Ziele, da ihr feines Gespür für Schönheit sie stets inspiriert und ihr kreatives Potenzial unterstützt.",
            cartIsEmpty: "Der Warenkorb ist jetzt leer",
            warnCart: "ALLE BESTELLUNGEN WERDEN IN EUR UND ZU DEN AKTUELLEN WECHSELKURSEN BEARBEITET",
            textAreaPlaceHolderCart: "Wie können wir Ihnen helfen?",
            addOrderNote: "Bestellnotiz hinzufügen",
            total: "Gesamt: ",
            followUs: "Folgen Sie mir auf Instagram",
            cartNotice1: "Internationale Sendungen können Zölle, Steuern und anderen Gebühren unterliegen, die nicht im Gesamtpreis enthalten",
            cartNotice2: "Als Kleinunternehmer im Sinne von § 19 Abs. 1 UStG wird keine Umsatzsteuer berechnet",
            privacyPolicyTitle: "Datenschutzerklärung",
            privacyPolicy: "##### **Datenschutzerklärung von ksartbykristinaandrus.de**  \n\n" +
            "Um Informationen zu den personenbezogenen Daten, dem Zweck und den Parteien, welchen diese Daten mitgeteilt werden, zu erhalten, kontaktieren Sie den Eigentümer  \n\n" +
            "##### **Anbieter und Verantwortlicher**  \n\n" +
            "Kristina Andrus  \n\n" +
            "An der Untertrave 54  \n\n" +
            "23552 Lübeck  \n\n" +
            "**E-Mail-Adresse des Anbieters:** ksartabstract@gmail.com  \n\n" +
            "##### **Arten der erhobenen Daten**  \n\n" +
            "Der Eigentümer stellt keine Auflistung der erhobenen personenbezogenen Daten zur Verfügung.  \n\n" +
            "Vollständige Details zu jeder Art von verarbeiteten personenbezogenen Daten werden in den dafür vorgesehenen Abschnitten dieser Datenschutzerklärung oder punktuell durch Erklärungstexte bereitgestellt, die vor der Datenerhebung angezeigt werden.  \n\n" +
            "Personenbezogene Daten können vom Nutzer freiwillig angegeben oder, im Falle von Nutzungsdaten, automatisch erhoben werden, wenn diese Anwendung genutzt wird.  \n\n" +
            "Sofern nicht anders angegeben, ist die Angabe aller durch diese Anwendung angeforderten Daten obligatorisch. Weigert sich der Nutzer, die Daten anzugeben, kann dies dazu führen, dass diese Anwendung dem Nutzer ihre Dienste nicht zur Verfügung stellen kann. In Fällen, in denen diese Anwendung die Angabe personenbezogener Daten ausdrücklich als freiwillig bezeichnet, dürfen sich die Nutzer dafür entscheiden, diese Daten ohne jegliche Folgen für die Verfügbarkeit oder die Funktionsfähigkeit des Dienstes nicht anzugeben.  \n\n" +
            "Nutzer, die sich darüber im Unklaren sind, welche personenbezogenen Daten obligatorisch sind, können sich an den Anbieter wenden.  \n\n" +
            "Jegliche Verwendung von Cookies – oder anderer Tracking-Tools – durch diese Anwendung oder Anbieter von Drittdiensten, die durch diese Anwendung eingesetzt werden, dient dem Zweck, den vom Nutzer gewünschten Dienst zu erbringen, und allen anderen Zwecken, die im vorliegenden Dokumentund in der Cookie-Richtlinie beschrieben sind.  \n\n" +
            "Die Nutzer sind für alle personenbezogenen Daten Dritter verantwortlich, die durch diese Anwendung beschafft, veröffentlicht oder weitergegeben werden.  \n\n" +
            "##### **Art und Ort der Datenverarbeitung**  \n\n" +
            "**Verarbeitungsmethoden**  \n" +
            "Der Anbieter verarbeitet die Nutzerdaten auf ordnungsgemäße Weise und ergreift angemessene Sicherheitsmaßnahmen, um den unbefugten Zugriff und die unbefugte Weiterleitung, Veränderung oder Vernichtung von Daten zu vermeiden.  \n\n" +
            "Die Datenverarbeitung wird mittels Computern oder IT-basierten Systemen nach organisatorischen Verfahren und Verfahrensweisen durchgeführt, die gezielt auf die angegebenen Zwecke abstellen. Zusätzlich zum Verantwortlichen könnten auch andere Personen intern (Personalverwaltung, Vertrieb, Marketing, Rechtsabteilung, Systemadministratoren) oder extern – und in dem Fall soweit erforderlich, vom Verantwortlichen als Auftragsverarbeiter benannt (wie Anbieter technischer Dienstleistungen, Zustellunternehmen, Hosting-Anbieter, IT-Unternehmen oder Kommunikationsagenturen) - diese Anwendung betreiben und damit Zugriff auf die Daten haben. Eine aktuelle Liste dieser Beteiligten kann jederzeit vom Anbieter verlangt werden.  \n\n" +
            "**Ort**  \n" +
            "Die Daten werden in der Niederlassung des Anbieters und an allen anderen Orten, an denen sich die an der Datenverarbeitung beteiligten Stellen befinden, verarbeitet.  \n\n" +
            "Je nach Standort der Nutzer können Datenübertragungen die Übertragung der Daten des Nutzers in ein anderes Land als das eigene beinhalten. Um mehr über den Ort der Verarbeitung der übermittelten Daten zu erfahren, können die Nutzer den Abschnitt mit den ausführlichen Angaben zur Verarbeitung der personenbezogenen Daten konsultieren.  \n\n" +
            "**Speicherdauer**  \n" +
            "Sofern in diesem Dokument nicht anderweitig festgelegt, werden personenbezogene Daten so lange verarbeitet und gespeichert, wie es der Zweck erfordert, zu dem sie erhoben wurden, und können ggf. aufgrund einer zu erfüllenden rechtlichen Verpflichtung oder basierend auf der Einwilligung des Nutzers auch länger aufbewahrt werden.  \n\n" +
            "##### **Cookie-Richtlinie**  \n\n" +
            "Diese Anwendung verwendet Tracker. Weitere Informationen ergeben sich aus der Cookie-Richtlinie.  \n\n" +
            "##### **Weitere Informationen für Nutzer**  \n\n" +
            "**Rechtsgrundlagen der Verarbeitung**  \n" +
            "Der Anbieter darf personenbezogene Daten von Nutzern nur dann verarbeiten, wenn einer der folgenden Punkte zutrifft:  \n\n" +
            "• Die Nutzer haben ihre Einwilligung für einen oder mehrere bestimmte Zwecke erteilt. Hinweis: In einigen Gesetzgebungen kann es dem Anbieter gestattet sein, personenbezogene Daten zu verarbeiten, bis der Nutzer einer solchen Verarbeitung widerspricht („Opt-out“), ohne sich auf die Einwilligung oder eine andere der folgenden Rechtsgrundlagen verlassen zu müssen. Dies gilt jedoch nicht, wenn die Verarbeitung personenbezogener Daten dem europäischen Datenschutzrecht unterliegt;  \n\n" +
            "• die Datenerhebung ist für die Erfüllung eines Vertrages mit dem Nutzer und/oder für vorvertragliche Maßnahmen daraus erforderlich;  \n\n" +
            "• die Verarbeitung ist für die Erfüllung einer rechtlichen Verpflichtung, der der Anbieter unterliegt, erforderlich;  \n\n" +
            "• die Verarbeitung steht im Zusammenhang mit einer Aufgabe, die im öffentlichen Interesse oder in Ausübung hoheitlicher Befugnisse, die dem Anbieter übertragen wurden, durchgeführt wird;  \n\n" +
            "• die Verarbeitung ist zur Wahrung der berechtigten Interessen des Anbieters oder eines Dritten erforderlich.  \n\n" +
            "In jedem Fall erteilt der Anbieter gerne Auskunft über die konkrete Rechtsgrundlage, auf der die Verarbeitung beruht, insbesondere darüber, ob die Angabe personenbezogener Daten eine gesetzliche oder vertragliche Verpflichtung oder eine Voraussetzung für den Abschluss eines Vertrages ist.  \n\n" +
            "**Weitere Informationen zur Verweildauer**  \n\n" +
            "Sofern in diesem Dokument nicht anderweitig festgelegt, werden personenbezogene Daten so lange verarbeitet und gespeichert, wie es der Zweck erfordert, zu dem sie erhoben wurden, und können ggf. aufgrund einer zu erfüllenden rechtlichen Verpflichtung oder basierend auf der Einwilligung des Nutzers auch länger aufbewahrt werden.  \n\n" +
            "Daher gilt:  \n" +
            "• Personenbezogene Daten, die zu Zwecken der Erfüllung eines zwischen dem Anbieter und dem Nutzer geschlossenen Vertrages erhoben werden, werden bis zur vollständigen Erfüllung dieses Vertrages gespeichert.  \n\n" +
            "• Personenbezogene Daten, die zur Wahrung der berechtigten Interessen des Anbieters erhoben werden, werden so lange aufbewahrt, wie es zur Erfüllung dieser Zwecke erforderlich ist. Nutzer können nähere Informationen über die berechtigten Interessen des Anbieters in den entsprechenden Abschnitten dieses Dokuments oder durch Kontaktaufnahme zum Anbieter erhalten.  \n\n" +
            "Darüber hinaus ist es dem Anbieter gestattet, personenbezogene Daten für einen längeren Zeitraum zu speichern, wenn der Nutzer in eine solche Verarbeitung eingewilligt hat, solange die Einwilligung nicht widerrufen wird. Darüber hinaus kann der Anbieter verpflichtet sein, personenbezogene Daten für einen längeren Zeitraum aufzubewahren, wenn dies zur Erfüllung einer gesetzlichen Verpflichtung oder auf Anordnung einer Behörde erforderlich ist.  \n\n" +
            "Nach Ablauf der Aufbewahrungsfrist werden personenbezogene Daten gelöscht. Daher können das Auskunftsrecht, das Recht auf Löschung, das Recht auf Berichtigung und das Recht auf Datenübertragbarkeit nach Ablauf der Aufbewahrungsfrist nicht geltend gemacht werden.  \n\n" +
            "**Die Rechte der Nutzer**  \n" +
            "Die Nutzer können bestimmte Rechte in Bezug auf ihre vom Anbieter verarbeiteten Daten ausüben.  \n\n" +
            "Nutzer haben im gesetzlich zulässigen Umfang insbesondere das Recht, Folgendes zu tun:  \n" +
            "• **Die Einwilligungen jederzeit widerrufen**. Hat der Nutzer zuvor in die Verarbeitung personenbezogener Daten eingewilligt, so kann er die eigene Einwilligung jederzeit widerrufen.  \n\n" +
            "• **Widerspruch gegen die Verarbeitung ihrer Daten einlegen**. Der Nutzer hat das Recht, der Verarbeitung seiner Daten zu widersprechen, wenn die Verarbeitung auf einer anderen Rechtsgrundlage als der Einwilligung erfolgt.  \n\n" +
            "• **Auskunft bezüglich ihrer Daten erhalten**. Der Nutzer hat das Recht zu erfahren, ob die Daten vom Anbieter verarbeitet werden, über einzelne Aspekte der Verarbeitung Auskunft zu erhalten und eine Kopie der Daten zu erhalten.  \n\n" +
            "• **Überprüfen und berichtigen lassen**. Der Nutzer hat das Recht, die Richtigkeit seiner Daten zu überprüfen und deren Aktualisierung oder Berichtigung zu verlangen.  \n\n" +
            "• **Einschränkung der Verarbeitung ihrer Daten verlangen**. Die Nutzer haben das Recht, die Verarbeitung ihrer Daten einzuschränken. In diesem Fall wird der Anbieter die Daten zu keinem anderen Zweck als der Speicherung verarbeiten.  \n\n" +
            "• **Löschung oder anderweitiges Entfernen der personenbezogenen Daten verlangen**. Die Nutzer haben das Recht, vom Anbieter die Löschung ihrer Daten zu verlangen.  \n\n" +
            "• **Ihre Daten erhalten und an einen anderen Verantwortlichen übertragen lassen**. Der Nutzer hat das Recht, seine Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten und, sofern technisch möglich, ungehindert an einen anderen Verantwortlichen übermitteln zu lassen.  \n\n" +
            "• **Beschwerde einreichen**. Die Nutzer haben das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.  \n\n" +
            "Die Nutzer haben auch das Recht, sich über die Rechtsgrundlage der Datenübermittlung ins Ausland oder an eine internationale Organisation, die dem Völkerrecht unterliegt oder von zwei oder mehr Ländern gegründet wurde, wie beispielsweise die UNO, sowie sich über die vom Anbieter ergriffenen Sicherheitsmaßnahmen zum Schutz ihrer Daten aufklären zu lassen.  \n\n" +
            "**Details zum Widerspruchsrecht bezüglich der Verarbeitung**  \n\n" +
            "**Werden personenbezogene Daten im öffentlichen Interesse, in Ausübung eines dem Anbieter übertragenen hoheitlichen Befugnisses oder zur Wahrung der berechtigten Interessen des Anbieters verarbeitet, kann der Nutzer dieser Verarbeitung widersprechen, indem er einen Rechtfertigungsgrund angibt, der sich auf seine besondere Situation bezieht.**  \n\n" +
            "**Nutzer werden darüber informiert, dass sie der Verarbeitung der personenbezogenen Daten für Direktwerbung jederzeit unentgeltlich ohne Angabe von Gründen widersprechen können. Widerspricht der Nutzer der Verarbeitung für Zwecke der Direktwerbung, so werden die personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet. Ob der Anbieter personenbezogene Daten für Direktwerbungszwecke verarbeitet, können die Nutzer den entsprechenden Abschnitten dieses Dokuments entnehmen.**  \n\n" +
            "**Wie die Rechte ausgeübt werden können**  \n\n" +
            "Alle Anfragen zur Ausübung der Nutzerrechte können über die in diesem Dokument angegebenen Kontaktdaten an den Anbieter gerichtet werden. Anfragen können kostenlos gestellt werden und werden vom Anbieter so früh wie möglich, spätestens innerhalb eines Monats, beantwortet und den Nutzern die gesetzlich vorgeschriebenen Informationen zur Verfügung gestellt. Jede Berichtigung oder Löschung personenbezogener Daten oder die Einschränkung der Verarbeitung teilt der Anbieter allen Empfängern, denen personenbezogene Daten offengelegt wurden, mit, falls es welche gibt. Es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden. Der Anbieter unterrichtet den Nutzer über diese Empfänger, wenn der Nutzer dies verlangt.  \n\n" +
            "##### **Weitere Informationen über die Erhebung und Verarbeitung von Daten**  \n\n" +
            "**Rechtliche Maßnahmen**  \n" +
            "Die personenbezogenen Daten des Nutzers können vom Anbieter zu Zwecken der Rechtsdurchsetzung innerhalb oder in Vorbereitung gerichtlicher Verfahren verarbeitet werden, die sich daraus ergeben, dass diese Anwendung oder die dazugehörigen Dienste nicht ordnungsgemäß genutzt wurden.  \n\n" +
            "Der Nutzer erklärt, sich dessen bewusst zu sein, dass der Anbieter von den Behörden zur Herausgabe von personenbezogenen Daten verpflichtet werden könnte.  \n\n" +
            "**Weitere Informationen über die personenbezogenen Daten des Nutzers**  \n" +
            "Zusätzlich zu den in dieser Datenschutzerklärung aufgeführten Informationen kann diese Anwendung dem Nutzer auf Anfrage weitere kontextbezogene Informationen zur Verfügung stellen, die sich auf bestimmte Dienste oder auf die Erhebung und Verarbeitung personenbezogener Daten beziehen.  \n\n" +
            "**Systemprotokolle und Wartung**  \n" +
            "Diese Anwendung und die Dienste von Dritten können zu Betriebs- und Wartungszwecken Dateien erfassen, die die über diese Anwendung stattfindende Interaktion aufzeichnen (Systemprotokolle), oder andere personenbezogene Daten (z. B. IP-Adresse) zu diesem Zweck verwenden.  \n\n" +
            "**Nicht in dieser Datenschutzerklärung enthaltene Informationen**  \n" +
            "Weitere Informationen über die Erhebung oder Verarbeitung personenbezogener Daten können jederzeit vom Anbieter über die aufgeführten Kontaktangaben angefordert werden.  \n\n" +
            "**Änderungen dieser Datenschutzerklärung**  \n" +
            "Der Anbieter behält sich vor, jederzeit Änderungen an dieser Datenschutzerklärung vorzunehmen, indem Nutzer auf dieser Seite und gegebenenfalls über diese Anwendung und/oder - soweit technisch und rechtlich möglich – durch das Versenden einer Mitteilung über dem Anbieter vorliegende Kontaktdaten der Nutzer informiert werden. Nutzern wird daher nahegelegt, diese Seite regelmäßig aufzurufen und insbesondere das am Seitenende angegebene Datum der letzten Änderung zu prüfen.  \n\n" +
            "Soweit Änderungen eine auf der Einwilligung des Nutzers basierte Datennutzung betreffen, so wird der Anbieter - soweit erforderlich - eine neue Einwilligung einholen.",
            termOfServiceTitle: "Nutzungsbedingungen",
            termOfService: "**Unsere Datenschutzrichtlinie legt fest, wie wir Ihre Daten verwenden.**    \n\n" +
                "##### **Auftragserteilung und Produktpreisangabe**  \n\n" +
                "Alle Bestellungen unterliegen der Verfügbarkeit und der Bestätigung des Bestellpreises. Wenn es ein Problem mit einer Bestellung gibt, werden wir uns mit Ihnen in Verbindung setzen. \n\n" +
                "Die Hauptmerkmale der Produkte werden auf jeder Produktseite auf ksartbykristinaandrus.de angezeigt. Die Bilder und die auf ksartbykristinaandrus.de zum Verkauf angebotenen Produkte entsprechen aufgrund des verwendeten Internetbrowsers, Smartphones oder Monitors möglicherweise nicht genau dem tatsächlichen physischen Produkt vom Kunden.  \n\n" +
                "Von Zeit zu Zeit kann es vorkommen, dass wir einen Artikel oder eine Bestellung falsch bepreisen. Wenn wir bei den von Ihnen bestellten Waren einen Fehler feststellen, teilen wir Ihnen dies so schnell wie möglich mit und geben Ihnen die Möglichkeit, Ihre Bestellung (zum korrekten Preis) erneut zu bestätigen oder zu stornieren es.  \n\n" +
                "Von Zeit zu Zeit können sich die Preise aufgrund von Wechselkursänderungen und anderen kommerziellen Faktoren ändern.   \n\n" +
                "Wir behalten uns das Recht vor, Bestellungen abzulehnen oder zu stornieren, die diesen Bedingungen nicht entsprechen, auch wenn Ihre Kredit- oder Debitkarte belastet wurde (und erstatten alle von Ihnen im Zusammenhang mit der Bestellung gezahlten Beträge vollständig zurück).  \n\n" +
                "##### **Geistiges Eigentum, Inhalte und Software**  \n\n" +
                "KristinaAndrus besitzt oder hat die Erlaubnis, die geistigen Eigentumsrechte an ihrer Website und ihren Inhalten zu nutzen. Diese Rechte sind weltweit geschützt. Alle Rechte sind vorbehalten.  \n\n" +
                "Sie dürfen den Inhalt unserer Website nur für Ihren persönlichen Gebrauch speichern, ausdrucken und anzeigen. Es ist Ihnen nicht gestattet, Teile der Website oder deren Inhalt für kommerzielle Zwecke zu nutzen, es sei denn, Sie haben unsere ausdrückliche Genehmigung.  \n\n" +
                "Es ist Ihnen auch nicht gestattet, das KSArt-Logo ohne unsere ausdrückliche Genehmigung zu verwenden.  \n\n" +
                "Es ist in Ordnung für Sie, auf ksartbykristinaandrus.de zu verlinken, solange Sie dies auf eine Weise tun, die nicht kommerziell, fair und legal ist und unseren Ruf nicht schädigt oder ausnutzt.  \n\n" +
                "Unsere Website darf ohne unsere Genehmigung nicht auf einer anderen Website eingebunden werden.  \n\n" +
                "##### **Kristina Andrus künstlerische Rolle und Verantwortlichkeiten**  \n\n " +
                "Wenn Sie einen Kauf auf ksartbykristinaandrus.de abschließen, gehen Sie einen Vertrag mit Kristina Andrus ein, die das Produkt liefert. Wir stellen Ihnen als Verbraucher eine Plattform zur Verfügung, auf der wir unsere Produkte verkaufen. Wir schließen einen Verkauf ab und wickeln Zahlungen, Rückerstattungen und Versand ab.“ Sobald wir Ihre vollständige Zahlung für eine Bestellung erhalten haben, erlöschen wir Ihre Schulden gegenüber Kristina Andrus Art für diese Bestellung. \n\n" +
                "Der Versand wird von Kristina Andrus über DHL oder einen anderen Lieferanten abgewickelt. \n\n" +
                "##### **Bestellbedingungen** \n\n" +
                "Durch Klicken auf die Schaltfläche „Bestätigen und bezahlen“ gehen Sie eine Zahlungsverpflichtung für das/die Produkt(e) ein. \n\n" +
                "Nachdem Ihre Bestellung aufgegeben wurde, werden wir Ihre Bestellung bearbeiten. Sie erhalten von uns eine automatische Bestellbestätigung. Dies stellt weder eine Annahme Ihrer Bestellung durch KristinaAndrus dar, noch kommt zu diesem Zeitpunkt ein Vertrag zwischen Kristina Andrus und dem Kunden zustande.“ Zeit.   \n\n" +
                "Die Annahme Ihrer Bestellung durch Kristina Andrus erfolgt, wenn wir Ihnen per E-Mail bestätigen, dass die Bestellung versandt wurde. Zwischen Ihnen und Kristina Andrus kommt ein Vertrag zustande. Die Annahme-E-Mail stellt eine elektronische Bestellbestätigung dar.  \n\n " +
                "Kristina Andrus ist nicht verpflichtet, eine Erstbestellung auszuführen und kann eine Bestellung nach unserem oder ihrem Ermessen ablehnen. \n\n" +
                "##### **Vorauszahlungs- und Rückerstattungsrichtlinien für Sonderanfertigungen** \n\n" +
                "Für alle Sonderanfertigungen ist eine Vorauszahlung von 50 % des Gesamtpreises erforderlich. Diese Vorauszahlung dient der Deckung der damit verbundenen Kosten und dient als Sicherheit für die Ausführung der Bestellung. \n\n" +
                "Bitte beachten Sie, dass die Vorauszahlung nicht erstattungsfähig ist. Das bedeutet, dass im Falle einer Stornierung der Bestellung durch den Kunden oder aus Gründen, die außerhalb unserer Kontrolle liegen, die gezahlte Anzahlung nicht zurückerstattet wird. \n\n" +
                "Die restlichen 50 % des Gesamtpreises sind mit Abschluss der Arbeiten und vor Lieferung der Endprodukte bzw. Dienstleistungen fällig. Erst nach vollständiger Bezahlung erfolgt die Übergabe der Endprodukte bzw. Dienstleistungen an den Kunden. \n\n" +
                "Wir behalten uns das Recht vor, die Bestellung zu stornieren, wenn die Anzahlung oder der Restbetrag nicht rechtzeitig bezahlt wird. \n\n",
            cookiePolicyTitle: "COOKIE POLICY",
            cookiePolicy: "##### **Cookie-Richtlinie von ksartbykristinaandrus.de** \n\n"
            + "Dieses Dokument informiert Nutzer über die Technologien, die diese Anwendung einsetzt, um die unten beschriebenen Zwecke zu erreichen. Diese Technologien ermöglichen den Zugriff auf oder die Speicherung von Informationen (z. B. durch Einsatz von Cookies) auf dem Gerät des Nutzers oder die Nutzung der Datenverarbeitungskapazitäten des Geräts, wenn der Nutzer diese Anwendung abruft oder ausführt." +
            "Der Einfachheit halber werden solche Technologien, sofern kein Anlass für eine Differenzierung besteht, innerhalb dieses Dokumentes als „Tracker“ definiert. Beispielsweise können Cookies zwar sowohl in Web- als auch in Mobilbrowsern zum Einsatz kommen, kommen jedoch als browserbasierte Tacker bei mobilen Apps nicht in Frage. Daher wird in diesem Dokument der Begriff Cookie nur dann verwendet, wenn konkret die Art von Tracker gemeint ist." +
            "Einige der Verarbeitungszwecke, zu denen Tracker eingesetzt werden, erfordern die Einwilligung des Nutzers. Wird die Einwilligung erteilt, so kann sie jederzeit nach dem in diesem Dokument beschriebenen Verfahren frei widerrufen werden." +
            "Diese Anwendung setzt nur anbietereigene Tracker ein (sogenannte „first-party Tracker“).  Die Geltungsdauer und der Verfall von first-party Cookies und anderen vergleichbaren Trackern hängen von der vom Anbieter festgelegten Lebensdauer ab.  \n\n" +
            "**Unbedingt erforderliche Vorgänge, um diese Anwendung zu betreiben und den Dienst zu erbringen**  \n\n" +
            "Diese Anwendung nutzt sogenannte „technische“ Cookies und ähnliche Tracker, um Vorgänge zu ermöglichen, die für die Durchführung und Erbringung des Dienstes unbedingt erforderlich sind.  \n\n" +
            "##### **Einstellungen verwalten und Einwilligungen erteilen oder widerrufen**  \n\n" +
            "Die Verwaltung von Tracker-Einstellungen sowie die Erteilung und der Widerruf von Einwilligungen können auf verschiedene Weisen erfolgen:" +
            "Nutzer können Tracker-Einstellungen direkt über die eigenen Geräteinstellungen verwalten, indem sie z. B. dem Einsatz oder der Speicherung von Trackern gänzlich widersprechen.  \n\n" +
            "Soweit der Einsatz von Trackern auf einer Einwilligung beruht, können Nutzer außerdem die Einwilligung durch entsprechende Auswahl im Cookie-Hinweis oder durch Änderung genannter Auswahl über das entsprechende Einwilligungs-Widget für Datenschutzpräferenzen (sofern vorhanden) erteilen oder widerrufen." +
            "Über die entsprechenden Browser- oder Geräteinstellungen können auch bereits abgespeicherte Tracker, einschließlich solcher, die für die Speicherung der ursprünglichen Einwilligungspräferenzen des Nutzers zuständig sind, gelöscht werden.  \n\n" +
            "Andere Tracker, die im lokalen Speicher des Browsers abgelegt sind, können durch Löschen des Verlaufs entfernt werden.  \n\n" +
            "##### **Trackereinstellungen verwalten**  \n\n" +
            "Nutzer können zum Beispiel unter den nachfolgenden Adressen Informationen zur Verwaltung von Cookies über die gängigsten Browser finden:  \n\n" +
            "* [Google Chrome](https://support.google.com/chrome/answer/95647?hl=de&p=cpn_cookies)  \n\n" +
            "* [Mozilla Firefox](https://support.mozilla.org/de/kb/verbesserter-schutz-aktivitatenverfolgung-desktop?redirectslug=cookies-erlauben-und-ablehnen&redirectlocale=de) \n\n" +
            "* [Apple Safari](https://support.apple.com/de-de/guide/safari/sfri11471/mac)  \n\n" +
            "* [Microsoft Internet Explorer](https://support.microsoft.com/en-gb/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d)  \n\n" +
            "* [Microsoft Edge](https://support.microsoft.com/de-de/microsoft-edge/cookies-in-microsoft-edge-löschen-63947406-40ac-c3b8-57b9-2a946a29ae09)  \n\n" +
            "* [Brave](https://support.brave.com/hc/en-us/articles/360022806212-How-do-I-use-Shields-while-browsing)  \n\n" +
            "* [Opera](https://help.opera.com/de/latest/web-preferences/#cookies)  \n\n" +
            "Nutzer können auch bestimmte innerhalb von mobilen Apps eingesetzte Tracker über entsprechende Geräteinstellungen verwalten – zum Beispiel über die Geräteinstellungen zu Werbung auf mobilen Geräten, oder generell über Trackingeinstellungen (Nutzer können Geräteinstellungen aufrufen und die entsprechenden Befehle suchen).  \n\n" +
            "##### **Folgen der Ablehnung der Verwendung von Trackern**  \n\n" +
            "Nutzer können frei entscheiden, ob sie ihre Einwilligung für die Verwendung von Trackern erteilen oder nicht. Es sollte jedoch beachtet werden, dass Tracker dazu beitragen, dass diese Anwendung den Nutzern eine bessere Nutzererfahrung und erweiterte Funktionen bieten kann (in Übereinstimmung mit den in diesem Dokument beschriebenen Zwecken). Sofern sich der Nutzer dafür entscheidet, die Verwendung von Trackern zu blockiern, ist der Anbieter daher möglicherweise nicht in der Lage, entsprechende Funktionen bereitzustellen.  \n\n" +
            "**Anbieter und Verantwortlicher**  \n\n" +
            "**E-Mail-Adresse des Anbieters:** ksartabstract@gmail.com  \n\n" +
            "Aufgrund der objektiven Komplexität von Tracking-Technologien, werden Nutzer aufgefordert, Kontakt zum Anbieter aufzunehmen, sofern sie weitere Informationen zum Einsatz solcher Technologien durch diese Anwendung zu erhalten wünschen.  \n\n",
            refundTitle: "ERSTATTUNG",
            refund: "##### **Schadensmeldung:** \n\n Eventuelle Schäden an gelieferten Kunstwerken müssen innerhalb von 3 Tagen nach Erhalt schriftlich per E-Mail gemeldet werden. Der Bericht sollte eine detaillierte Beschreibung des Schadens sowie Fotos davon enthalten Betroffenes Kunstwerk. \n\n" +
            "##### **Rückerstattungsanspruch:** \n\n Der Kunde hat nur dann Anspruch auf eine Rückerstattung, wenn der Schaden innerhalb der oben genannten Frist gemeldet wird. Die Rückerstattung erfolgt erst nach Rückgabe des beschädigten Kunstwerks. \n \n" +
            "##### **Rücksende- und Versandkosten:** \n\n Der Käufer ist für die ordnungsgemäße Rücksendung des beschädigten Kunstwerks verantwortlich. Die Versandkosten für die Rücksendung sind vom Käufer zu tragen. \n\n " +
            "##### **Rückgabebedingungen:** \n\n Das Kunstwerk muss im Originalzustand und in der Originalverpackung zurückgegeben werden. Die Rückgabe muss innerhalb einer angemessenen Frist nach Erhalt der Rücksendeanweisungen erfolgen. \n\n" +
            "##### **Rückerstattung:** \n\n Die Rückerstattung erfolgt nach Erhalt und Prüfung des zurückgegebenen Kunstwerks. Wir behalten uns das Recht vor, eine Rückerstattung zu verweigern, wenn das Kunstwerk die Rückgabebedingungen nicht erfüllt.",
            shippingPolicyTitle: "WELTWEITER VERSAND",
            shippingPolicy: "Die Lieferung innerhalb der Länder der Europäischen Union ist im Preis inbegriffen. Der Versand erfolgt innerhalb einer Woche. \n\n" +
            "**Wichtige Informationen zur Lieferung außerhalb der Länder der Europäischen Union:** \n\n" +
            "Für internationale Sendungen können Zölle, Steuern und zusätzliche Gebühren anfallen, die nicht im Gesamtpreis enthalten sind. Bitte kontaktieren Sie mich per E-Mail oder Instagram, um die Versandkosten zu klären. Vielen Dank für Ihr Verständnis \n\n",
            commissionedWork: "Preise für Auftragsarbeit:\n" +
                "\n" +
                " • 130x100 cm - 690€ \n\n" +
                " • 100x100 cm - 560€ \n\n" +
                " • 80x100 cm - 490€"
        }
    };

    const [currentLanguage, setCurrentLanguage] = useState(null);
    const [loading, setLoading] = useState(true);

    const setLanguage = (newLanguage) => {
        if (languageStrings[newLanguage]) {
            setCurrentLanguage(newLanguage);
        } else {
            console.warn(`Language '${newLanguage}' not supported.`);
        }
    };

    useEffect(() => {
        // Simulating an asynchronous operation (fetching user location and setting language)
        const fetchData = async () => {
            try {
                // Fetch user location and set default language
                const userLocationResponse = await fetch('https://api64.ipify.org?format=json');
                const userData = await userLocationResponse.json();
                // const locationResponse = await fetch(`https://ipinfo.io/${userData.ip}/json?token=61533846530f0e`);
                const locationResponse = await fetch('https://ksartbykristinaandrus.de/api/records', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        method: 'get-language-info',
                        ip: userData.ip
                    }),
                });
                console.log("Response: ", locationResponse);
                const locationData = await locationResponse.json();

                setLanguage(locationData.country === 'DE' ? 'DE' : 'ENG');
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user location:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const contextValue = {
        strings: languageStrings[currentLanguage],
        setLanguage,
        currentLanguage,
    };
    if (loading) {
        return <LoadingComponent />;
    }

    return <LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>;
};

export const useLanguage = () => {
    return useContext(LanguageContext);
};
