import React, {useContext, useEffect} from "react";
import LoginPage from "./LoginPage";
import Dashboard from "./Dashboard";
import {AuthContext} from "../authorization/AuthContext";

export default function AdminPanel () {
    const { isAuth, login, logout } = useContext(AuthContext);

    useEffect(() => {
        console.log(`isAuth in AdminPanel ${isAuth}`);
    }, [isAuth]);
    return (
        <>
            { isAuth === false || isAuth === null  ?
                ( <LoginPage login={login}/> )
            :
                ( <Dashboard logout={logout} /> )
            }
        </>
    );
}

