import React, {useContext, useEffect, useRef, useState} from "react";
import {ShopContext} from "../context/shoppingContext";
import {useNavigate, useParams} from "react-router-dom";
import Carousel from "react-multi-carousel";
import ReactMarkdown from "react-markdown";
import AnimatedButton from "../components/AnimatedButton";
import {useLanguage} from "../context/languageContext";
import LoadingComponent from "../components/LoadingComponent";
import ToggleButton from "../components/ToggleButton";
export default function ProductDetails(){
    const { addToCart, products, getUpdatedProducts, productsUpdate} = useContext(ShopContext);
    const { currentLanguage, strings } = useLanguage();
    const { id } = useParams();
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);
    const [product, setProduct] = useState({});
    const [loading, setLoading] = useState(false);
    const [imageSelected, setImageSelected] = useState(0);
    const [images, setImages] = useState([]);
    const [description, setDescription] = useState("");
    const videoRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false);
    const [selectedOption, setSelectedOption] = useState(strings.frameTypes[0]);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        handleUpdateProducts(true, event.target.value);
    };
    const handleToggleChange = () => {
        setIsChecked(!isChecked); // Toggle the isChecked state
        handleUpdateProducts(!isChecked, selectedOption);
    };

    useEffect(() => {
        if (products.length < 1) return
        handleProductGet(id)
    }, [products]);

    const handleProductGet = (id) => {
        const foundProduct = products.find(product => product.id === id);
        if (!foundProduct) return
        setProduct(foundProduct);
        setImages(foundProduct.image);
        if (currentLanguage === "ENG") setDescription(foundProduct.eng);
        if (currentLanguage === "DE") setDescription(foundProduct.de);
        setLoading(true);
    };

    useEffect(() => {
        if (!loading) return;
        if (currentLanguage === "ENG") setDescription(product.eng);
        if (currentLanguage === "DE") setDescription(product.de);
    }, [currentLanguage]);

    const addToCartHandle = (id) => {
        setIsHovered(!isHovered);
        addToCart(id);
        navigate("/cart");
    };
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update the window width
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // Listen to the window resize event
        window.addEventListener('resize', handleWindowResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    const items = [5, 4, 3, 2, 1];
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: items[0],
            slidesToSlide: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 992 },
            items: items[2],
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 991, min: 700 },
            items: items[4],
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 600, min: 450 },
            items: items[4],
            slidesToSlide: 1
        },
        small: {
            breakpoint: { max: 450, min: 100 },
            items: items[4],
            slidesToSlide: 1
        }
    };

    const handleImageSelect = (index) => {
        setImageSelected(index);
    };

    const handleVideoEnd = () => {
        videoRef.current.currentTime = 0;
        videoRef.current.play();
    };

    const handleUpdateProducts = (isChecked, selectedOption) => {
        let newName = product.name;
        let newPrice = product.price;

        if (isChecked) {
            if (!newName.includes(',')) {
                newPrice = (Number(newPrice) + 120).toString();
            }
            newName = newName.includes(',')
                ? newName.split(', ')[0] + `, ${selectedOption}`
                : newName + `, ${selectedOption}`;
        } else {
            if (newName.includes(',')) {
                newPrice = (Number(newPrice) - 120).toString();
                newName = newName.split(', ')[0];
            }
        }
        productsUpdate(getUpdatedProducts(newName, newPrice, id));
    };

    return !loading ? (
        <section>
            <LoadingComponent />
        </section>
    ) : (
        <section>
            <div className="container">
                <div className="row">
                    {windowWidth > 991 ?
                        // Big screen
                        <div className="col-12 col-lg-6">
                            <div className="justify-content-center align-content-center d-flex" style={{marginBottom: 30, borderRadius: 8}}>
                                {
                                    images[imageSelected].toLowerCase().endsWith('.mp4') ? (
                                        <video src={images[imageSelected]} width="100%" autoPlay={true} muted={true}
                                               playsInline={true} controls={false} preload={"none"}
                                               style={{maxWidth: 400, borderRadius: 8}}></video>
                                    ) : (
                                        <img src={images[imageSelected]} alt="" width={"100%"} style={{maxWidth: 400, borderRadius: 8}}/>
                                    )
                                }
                            </div>
                            <Carousel
                                responsive={responsive}
                            >
                                {images.map((item, index) => (
                                    <div key={index}
                                         style={{ paddingBottom: '120%', position: 'relative', overflow: 'hidden', width: "90%", borderRadius: 8 }}
                                         // style={{ backgroundColor: "#563737", width: "90%", borderRadius: 8 }}
                                    >
                                        {item.toLowerCase().endsWith('.mp4') ? (
                                            // <VideoThumbnail src={item}/>
                                            <video src={item} width="100%" autoPlay={false} muted={true}
                                                   playsInline={false} controls={false} preload={true}
                                                   style={{
                                                    position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover',
                                                    border: "1px solid",
                                                    borderColor: index === imageSelected ? "#000000" : "#ffffff",
                                                    borderRadius: 8,
                                                    backgroundColor: "transparent" }}
                                                    onClick={() => handleImageSelect(index)}
                                                    >
                                            ></video>
                                        ) : (
                                            <img
                                                src={item}
                                                alt=""
                                                width={"100%"}
                                                onClick={() => handleImageSelect(index)}
                                                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover',
                                                    border: "1px solid",
                                                    borderColor: index === imageSelected ? "#000000" : "#ffffff",
                                                    borderRadius: 8
                                                }}
                                            />
                                        )}
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                        :
                        // Small screen
                        <div className="col-12 col-lg-6" style={{maxWidth: 500}}>
                            <Carousel
                                responsive={responsive}
                                showDots={true}
                                swipeable={true}
                                draggable={true}>
                                {images.map((item, index) => (
                                    item.toLowerCase().endsWith(".mp4") ? (
                                            <video src={item} key={index} width="100%" autoPlay={true} muted={true} ref={videoRef}
                                                   playsInline={true} controls={false} loop={true} preload={"none"} onEnded={handleVideoEnd}
                                                   style={{
                                                       borderRadius: 8,
                                                       backgroundColor: "transparent"}}>
                                                ></video>
                                        ) : (
                                            <img src={item} alt="" width={"100%"} style={{ borderRadius: 8}} key={index}/>
                                        )
                                    ))}
                            </Carousel>
                        </div>
                    }


                    <div className="col-12 col-lg-6">
                        <h3>{product.name}</h3>
                        <p>{product.sale_price && <span style={{
                            color: "#a20202",
                        }}>€{product.sale_price} </span>}<span style={{
                            textDecorationLine: product.sale_price && "line-through"
                        }}>€{product.price}</span></p>
                        <div style={{marginBottom: 60, marginTop: 40, display: "flex", justifyContent: "left"}}>
                            <AnimatedButton handleOnClick={() => addToCartHandle(product.id)} title={"Add to cart"} width={100}/>
                        </div>
                        <div className="d-flex">
                            <h5 style={{
                                paddingRight: 16,
                                color: "#444444"
                            }}>{strings.addFrame}</h5>
                            <ToggleButton handleToggleChange={handleToggleChange} isChecked={isChecked} />
                        </div>
                        <fieldset style={{
                            marginBottom: "20px"
                        }}>
                            {isChecked &&
                                strings.frameTypes.map((item, index) => (
                                    <label key={index} className="col-12">
                                        <input
                                            type="radio"
                                            value={item}
                                            checked={selectedOption === item}
                                            onChange={handleOptionChange}
                                            style={{
                                                marginRight: "16px"
                                            }}
                                        />
                                        {item}
                                    </label>
                                ))
                            }
                        </fieldset>
                        <p>
                            <ReactMarkdown>{description}</ReactMarkdown>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}