import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

export default function Gallery() {
    const [images, setImages] = useState([]);
    const navigate = useNavigate();

    const styles = [
        {
            width: "100%",
            height: "auto",
            marginBottom: "12px"
        },
        {
            position: "absolute",
            left: "47%",
            width: "auto",
            top: "20px"
        }
    ]

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Send username and password to server for authentication
                const response = await fetch('https://ksartbykristinaandrus.de/api/records', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ method: "6" }),
                });
                const data = await response.json();
                // Handle authentication response
                setImages(data);
            } catch (error) {
                console.error('Error:', error);
            }
        }
        fetchData();
    }, []);

    const navigateHandle = (url) => {
        navigate(url.replace("https://ksartbykristinaandrus.de/images/", "gallery-item/"));
    };
    const handleDragStart = (e, index) => {
        e.dataTransfer.setData('index', index);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const handleDrop = (e, targetIndex) => {
        e.preventDefault();
        const sourceIndex = e.dataTransfer.getData('index');
        const updatedImages = [...images];
        const item = updatedImages.splice(sourceIndex, 1)[0];
        updatedImages.splice(targetIndex, 0, item);
        setImages(updatedImages);
    };
    const handleSaveChanges = () => {
        if (!window.confirm('Are you confirm changes?')) {
            return;
        }
        try {
            // Send username and password to server for authentication
            // const response =
             fetch('https://ksartbykristinaandrus.de/api/records', {
                 method: 'POST',
                 headers: {
                     'Content-Type': 'application/json',
                 },
                 body: JSON.stringify({method: "7", images: images}),
             });
            // const data = response.json();
            // console.log(data);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
      <div className="row" style={{margin: "auto", marginTop: "20px", maxWidth: "800px"}}>
          {images.map((item, index) => (
              <div className="col-6 col-md-4" key={index}>
                  <img src={item} alt={item} style={styles[0]}
                       onDoubleClick={() => navigateHandle(item)}
                       draggable
                       onDragStart={(e) => handleDragStart(e, index)}
                       onDragOver={handleDragOver}
                       onDrop={(e) => handleDrop(e, index)}
                  />
              </div>
          ))}
          <button onClick={() => handleSaveChanges()} style={styles[1]}>Save</button>
      </div>
    );
}