import {useLanguage} from "../context/languageContext";
import {useContext} from "react";
import {ShopContext} from "../context/shoppingContext";
import ProductContainer from "../cart/components/ProductContainer";

export default function Store() {
    const strings = useLanguage();
    const {products} = useContext(ShopContext);
    return (
      <section className="store container">
          <h5 className="text-center"
            style={{marginTop: 20, marginBottom: 30, color: "#4d4c4c", fontFamily: 'Wire One', fontWeight: "bold"}}>{strings.storeTitle}</h5>
          <div className="row">
              {products.map((item, index) => (
                  <div className="col-6 col-md-4 col-lg-3">
                    <ProductContainer product={item} key={index} />
                  </div>
              ))}
          </div>
      </section>
    );
}