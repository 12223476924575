import "./ProductPlace.css";
// import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ProductContainer from "../cart/components/ProductContainer";
import React, {useContext} from "react";
import {ShopContext} from "../context/shoppingContext";
import {useNavigate} from "react-router-dom";
import {useLanguage} from "../context/languageContext";
import AnimatedButton from "../components/AnimatedButton";
import ReactMarkdown from "react-markdown";

export default function ProductPlace() {
    const navigate = useNavigate();
    const { products } = useContext(ShopContext);
    const { strings } = useLanguage();

    const divStyles = {
        width: '100%',
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    };

    const handleNavigateToStore = () => {
        navigate("/store")
    };
    return (
      <div style={{width: 95 + "%", paddingLeft: 5 + "%", marginTop: 40, marginBottom: 40}}>
          {/*<div style={{*/}
          {/*    textAlign: "center", marginBottom: 40*/}
          {/*}}><ReactMarkdown>{strings.commissionedWork}</ReactMarkdown>*/}
          {/*</div>*/}
          <div className="row">{
              products.slice(0, 4).map((item, index) => (
                  <div className="col col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" key={index}>
                    <ProductContainer product={item} key={index} />
                  </div>
              ))
          }
              <div style={divStyles}>
                  <AnimatedButton handleOnClick={handleNavigateToStore} title={strings.moreArt} width="100%"/>
              </div>
          </div>
      </div>
    );
}
