import {useEffect, useState} from "react";
import SoldSVG from "../assets/sold.svg";
import {useNavigate} from "react-router-dom";

export default function ProductTableContent({product}) {
    const [isDisplayed, setIsDisplayed] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const [currentImage, setCurrentImage] = useState(product.image[0] || "");
    const navigate = useNavigate();

    const toggleContent = (status) => {
        setIsDisplayed(status);
        if (status) {
            setContentHeight(document.getElementById(`content${product.id}`).scrollHeight);
        } else {
            setContentHeight(0);
        }
    };

    useEffect(() => {
        function handleResize() {
            toggleContent(false);
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [toggleContent]);

    const handleSold = () => {
        // Check product was sold or not
        if (product.price.includes('S')) {
            return;
        }
        try {
            // Send username and password to server for authentication
            fetch('https://ksartbykristinaandrus.de/api/records', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({method: "10", productId: product.id, price: product.price}),
            }).then(r => r);
        } catch (error) {
            console.error('Error:', error);
        }
        window.location.reload();
    };

    return (
        <>
            <div className="row" style={{ padding: "16px", backgroundColor: "#855b5b", color: "#ffe7e7", border: "1px solid black" }}>
                <div className="col-6">
                    {product.name + "   Price: €" + product.price + " Sale price: €" + product.sale_price}
                </div>
                <div className="col-6 d-flex justify-content-between align-items-center">

                    <img src={SoldSVG} alt="" onClick={() => handleSold()}/>
                    <svg xmlns="http://www.w3.org/2000/svg" className="feather feather-edit" fill="none" height="24" stroke="currentColor"
                         strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24"
                        onClick={() => navigate(`product-item/${product.id}`)}>
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/>
                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/>
                    </svg>
                    <svg fill="#ffffff" height="22px" width="22px" version="1.1" viewBox="0 0 490 490" onClick={() => toggleContent(!isDisplayed)}>
                        <path d="M490,165.37l-91.698-88.842L245,231.766L91.698,76.528L0,165.37l245,248.102L490,165.37z M91.219,119.611L245,275.343  l153.781-155.732l47.717,46.237L245,369.91L43.502,165.848L91.219,119.611z"/>
                    </svg>
                </div>
            </div>
            <div id={`content${product.id}`}
                 style={{ overflow: "hidden", height: `${contentHeight}px`, transition: "height 0.5s ease-in-out" }}
                >
                <div className="row">
                <div className="col-12 col-lg-8">
                    {product.image.map((image, index) => (
                        <p onClick={() => setCurrentImage(image)} key={index} style={{cursor: "pointer"}}>{image} <br /></p>
                    ))}
                </div>
                <div className="col-12 col-lg-4">
                    <img src={currentImage} alt="TEST" width={300}/>
                </div>
                    <div className="col-12 col-lg-6">
                        <b>Description DE</b><br/><br/>
                        {product.de}
                    </div>
                    <div className="col-12 col-lg-6">
                        <b>Description ENG</b><br/><br/>
                        {product.eng}
                    </div>
                </div>
            </div>
        </>
    );
}