import React, {useState} from "react";

export default function LoginPage({ login }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send username and password to server for authentication
            const response = await fetch('https://ksartbykristinaandrus.de/api/records', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ method: "login", username, password }),
            });
            const data = await response.json();
            // Handle authentication response
            if (data.ok === "true") {
                login();
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const styles = [
        {
            maxWidth: "400px",
            width: "90%",
            border: "solid 2px black",
            marginTop: "5%"
        },
        {
            width: "90%",
            margin: "5%",
            padding: 0
        },
        {
            borderRadius: "0.7rem",
            width: "50%",
            margin: "4% 24% 6% 24%",
            backgroundColor: "#40a1a1"
        }
    ]
    return (
        <section className="container">
            <div className="d-flex justify-content-center align-items-center">
                <div style={styles[0]}>
                    <h2 className="text-center">Login</h2>
                    <form onSubmit={handleSubmit}>
                        <div>
                            {/*<label htmlFor="username">Username:</label>*/}
                            <p className="text-center"
                               style={styles[1]}>
                                Username
                            </p>
                            <input
                                type="text"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                                style={styles[1]}
                            />
                        </div>
                        <div>
                            <p className="text-center"
                               style={styles[1]}>
                                Password
                            </p>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                style={styles[1]}
                            />
                        </div>
                        <button type="submit" style={styles[2]}>Sign In</button>
                    </form>
                </div>
            </div>
        </section>
    );
}