import {useEffect, useState} from "react";
import ImageItem from "../components/ImageItem";

export default function AddProductItem() {
    const [title, setTitle] = useState('');
    const [descriptionEng, setDescriptionEng] = useState('');
    const [descriptionDe, setDescriptionDe] = useState('');
    const [price, setPrice] = useState('');
    const [uploadedPaths, setUploadedPaths] = useState([]);
    const [descriptionId, setDescriptionId] = useState('');

    const [images, setImages] = useState([]);

    // Handle file selection
    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const validFiles = selectedFiles.filter(file => {
            const fileType = file.type.split('/')[1]; // Get file extension
            return ['jpeg', 'jpg', 'png', 'svg'].includes(fileType);
        });
        setImages(prevImages => [...prevImages, ...validFiles]);

    };

    const clearData = () => {
        setTitle('');
        setDescriptionEng('');
        setDescriptionDe('');
        setPrice('');
        setUploadedPaths([]);
        setDescriptionId('');
        setImages([]);
    };


    const handleSubmit = (event) => {
        if (!window.confirm('Are you confirm changes?')) {
            return;
        }
        event.preventDefault();
        const formData = new FormData();
        formData.append('method', 'add_description');
        formData.append('descriptionEng', descriptionEng);
        formData.append('descriptionDe', descriptionDe);

        fetch('https://ksartbykristinaandrus.de/api/records', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log('Upload successful:', data);
                setDescriptionId(data.maxId);
            })
            .catch(error => {
                console.error('Error uploading files:', error);
            });
        // You can perform further processing, such as sending the data to the server, here
        // console.log(uploadedPaths, title, descriptionEng, descriptionDe, price );
    };



    useEffect(() => {
        if (Number(descriptionId) > 0) {
            const formData = new FormData();
            formData.append('method', 'add_product_item');
            formData.append('name', title);
            formData.append('image', JSON.stringify(uploadedPaths));
            formData.append('description_id', descriptionId);
            formData.append('price', price);

            fetch('https://ksartbykristinaandrus.de/api/records', {
                method: 'POST',
                body: formData
            })
                .then(response => response.json())
                .then(data => {
                    window.alert('Product added successfully: ' + data.success);
                    clearData();
                })
                .catch(error => {
                    console.error('Error adding product:', error);
                });
        }
    }, [descriptionId]);

    const handleUpload = () => {
        const formData = new FormData();
        images.forEach((file, index) => {
            formData.append(`file${index}`, file);
        });
        formData.append('method', 'upload_product_images');

        fetch('https://ksartbykristinaandrus.de/api/records', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log('Upload successful:', data);
                setUploadedPaths(data.paths);
            })
            .catch(error => {
                console.error('Error uploading files:', error);
            });
    };

    const handleDragStart = (e, index) => {
        e.dataTransfer.setData('index', index);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const handleDrop = (e, targetIndex) => {
        e.preventDefault();
        const sourceIndex = e.dataTransfer.getData('index');
        const updatedImages = [...images];
        const item = updatedImages.splice(Number(sourceIndex), 1)[0];
        updatedImages.splice(targetIndex, 0, item);
        setImages(updatedImages);
    };


    const handleRemoveImage = (image) => {
        setImages(images.filter(item => item.name !== image.name))
    };

    return (
        <div className="container">
            <input type="file" multiple onChange={handleFileChange} style={{
                marginTop: 50
            }}/>
            <button onClick={handleUpload}>Upload</button>

            <div className="row" style={{
                marginTop: 50
            }}>
                {images.map((image, index) => (
                    <div className="col-lg-2" key={index}>
                        <ImageItem image={image} link={URL.createObjectURL(image)} index={index} onDragStart={(e) => handleDragStart(e, index)} handleDragOver={handleDragOver} onDrop={(e) => handleDrop(e, index)} removeImage={handleRemoveImage}/>
                    </div>
                ))}
            </div>

            <form onSubmit={handleSubmit}>
                <div className="row" style={{
                    marginTop: 50
                }}>
                    <label className="col-lg-2">Title:</label>
                    <input
                        type="text"
                        value={title}
                        onChange={(event) => setTitle(event.target.value)}
                        required
                        className="col-lg-4"
                    />
                </div>
                <div className="row" style={{
                    marginTop: 50
                }}>
                    <label className="col-lg-2">Description (English):</label>
                    <textarea
                        value={descriptionEng}
                        onChange={(event) => setDescriptionEng(event.target.value)}
                        required
                        className="col-lg-4"
                    />
                </div>
                <div className="row" style={{
                    marginTop: 50
                }}>
                    <label className="col-lg-2">Description (German):</label>
                    <textarea
                        value={descriptionDe}
                        onChange={(event) => setDescriptionDe(event.target.value)}
                        required
                        className="col-lg-4"
                    />
                </div>
                <div className="row" style={{
                    marginTop: 50
                }}>
                    <label className="col-lg-2">Price:</label>
                    <input
                        type="number"
                        value={price}
                        onChange={(event) => setPrice(event.target.value)}
                        required
                        className="col-lg-4"
                    />
                </div>
                <div className="d-flex justify-content-center" style={{
                    marginTop: 50
                }}>
                    <button type="submit" style={{
                        border: "none",
                        backgroundColor: "#ef8d8d",
                        padding: "12px 46px",
                        margin: "auto", borderRadius: 16,
                    }} disabled={uploadedPaths.length === 0}>Submit</button>
                </div>
            </form>
        </div>
    );
}