import React, {useState} from "react";

export default function AnimatedButton({handleOnClick, title}) {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const buttonStyle = {
        border: '1px solid #000000',
        cursor: 'pointer',
        height: 40,
        backgroundColor: 'rgb(243,234,227)',
        borderRadius: 8,
        position: "relative",
    };

    const overlayStyle = {
        height: '100%',
        width: isHovered ? '100%' : '0',
        backgroundColor: '#000000',
        margin: 0,
        padding: 0,
        transition: 'width 0.5s ease-in-out',
        borderRadius: 6
    };
    return (
        <div
            className="button-more-art"
            onClick={handleOnClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={buttonStyle}
            >
            <div style={overlayStyle}></div>
            <span style={{
                position: "absolute",
                top: 8,
                left: '50%', transform: 'translateX(-50%)', textAlign: 'center',
                color: "gray",
                fontWeight: "600",
                fontFamily: 'EB Garamond'}}>
                {title}
            </span>
        </div>
    );
}