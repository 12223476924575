import LogoBold from "../assets/image/logo-bold.png";
import "./AboutMe.css";
import { useLanguage } from "../context/languageContext";
import ReactMarkdown from "react-markdown";
export default function AboutMe() {
    const { strings } = useLanguage();
    const aboutme_section_style = {
        width: "100%",
        minHeight: "97vh",
        paddingBottom: "250px",
        paddingTop: "100px",
        // background: "rgb(238,230,218)",
        backgroundColor: "rgb(243,234,227)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }

    const ksart_img_style = {
        // transform: "rotate(-90deg)",
    }

    const photoStyle = {
        width: "180px",
        height: "180px",
        // transform: 'translate(-50%, -50%)',
        objectFit: "cover",
        borderRadius: '8px',
        objectPosition: "0px 0px"
    }

    return (
        <section className="aboutme-section" style={aboutme_section_style}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
                        <img src={LogoBold} alt="" style={ksart_img_style} width={300} className="img-fluid rotate-on-md"/>
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center" style={{position: "relative"}}>
                        <ReactMarkdown
                            className="w-100 text-lg-start"
                            components={{
                                p: ({node, ...props}) => <p style={{ fontFamily: 'Teko, sans-serif', color: "#4d4c4c", fontSize: "14px" }} {...props} />
                                    }}
                        >
                            {strings.aboutMeText}
                        </ReactMarkdown>
                        <div
                            style={{
                                position: 'absolute',
                                borderRadius: '8px',
                                top: '110%',
                                left: '50%',
                                width: '180px',
                                height: '180px',
                                overflow: "hidden",
                                // backgroundColor: "green"
                            }}
                        >
                            <img src="https://ksartbykristinaandrus.de/images/IMG_7873.JPG" alt="" style={photoStyle}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}