import {useLanguage} from "../context/languageContext";
import {useEffect, useState} from "react";
import axios from "axios";

export default function Galerry() {
    const {strings} = useLanguage();
    const [images, setImages] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post('https://ksartbykristinaandrus.de/api/records',
                    { method: "2"});
                setImages(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <section className="align-items-center justify-content-center" style={{paddingTop: 100, paddingBottom: 100}}>
            <h4 className="text-center">{strings.followUs}</h4>
            <div className="row mx-auto" style={{maxWidth: 780}}>
                {images.map((item, index) => (
                    <div className="col-6 col-md-6 col-lg-4" style={{marginTop: 16}} key={index}>
                        <img src={item.url} alt="" className="img-fluid rounded"
                             style={{ width: '100%', maxWidth: '250px' }}/>
                    </div>
                ))}
            </div>
        </section>
    );
}
