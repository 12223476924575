import React from 'react';
import {useNavigate} from "react-router-dom";
export default function ProductContainer({ product, width }) {
    const navigate = useNavigate();
    // const minWidthValue = typeof width === 'number' && !isNaN(width) ? width - 16 : 0;

    return (
        <div className="text-center"
             onClick={() => navigate("/product/" + product.id)}
        >
            <div className="position-relative" style={{
                paddingBottom: '140%', position: 'relative', overflow: 'hidden'
            }}>
                { product.sale_price && <div style={{
                    position: "absolute", left: 0, right: 0, top: 30, zIndex: 3,
                    fontSize: 32, color: "#e50707", fontWeight: 600, width: "100%",
                    // backgroundColor: "rgba(255,255,255,0.8)"
                }}>SALE</div>}
                <img
                    src={product.image[0]}
                    alt={product.name}
                    width={98 + "%"}
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', cursor: "grab", borderRadius: 8 }}
                    // style={{display: "inline-block", minWidth: minWidthValue, cursor: "grab", borderRadius: 8}}
                    className="img-fluid"
                />
            </div>
            <h5 className="mt-0" style={{color: "#4d4c4c", fontFamily: 'EB Garamond', fontWeight: "bold", fontSize: "18px"}}>{product.name}</h5>
            <p style={{ color: 'gray', fontSize: 'smaller' }}>
                {product.sale_price && <span style={{
                    color: "#a20202",
                }}>€{product.sale_price} </span>}
                <span style={{
                    textDecorationLine: product.sale_price && "line-through"
                }}>{product.price === "0" ? "Sold" : `€${product.price}`}</span>
            </p>
        </div>
    );
}