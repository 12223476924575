import {useContext, useEffect, useState} from "react";
import {ShopContext} from "../../context/shoppingContext";

export default function CartProductContainer({id}){
    const { removeFromCart, products } = useContext(ShopContext);
    const [images, setImages] = useState([]);
    const [name, setName] = useState("");
    const [price, setPrices] = useState("");
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    // Function to update screenWidth state
    const updateScreenWidth = () => {
        setScreenWidth(window.innerWidth);
    };

    useEffect(() => {
        // Event listener to update screenWidth on resize
        window.addEventListener('resize', updateScreenWidth);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, []);

    useEffect(() => {
        const foundProduct = products.find(product => product.id === id);
        setImages(foundProduct.image);
        setName(foundProduct.name);
        setPrices(foundProduct.sale_price ? foundProduct.sale_price  : foundProduct.price );
    }, [id]);

    const containerTdStyle = {
        display: "flex"
    }

    const rowDivStyle = {
        display: "flex"
    }

    const buttonRemoveStyleMobile = {
        border: "none",
        background: "transparent",
        textDecoration: "underline",
        paddingBottom: "16px"
    }

    const buttonRemoveStyle = {
        border: "none",
        background: "transparent",
        textDecoration: "underline",
        paddingBottom: "16px",
        marginLeft: "10vw"
    }

    const columnDivStyle = {
        paddingLeft: "12px",
        paddingTop: "20%"
    }
    return (
        <tr>
            <td style={containerTdStyle}>
                <div style={rowDivStyle}>
                    <img src={images[0]} alt="" width="40%" style={{marginTop: 12, maxWidth: "300px", borderRadius: 8}}/>
                    <div style={columnDivStyle}>
                        <h4 style={{fontFamily: 'Teko', color: "#4d4c4c"}}>{name}</h4>
                        {/*<p style={{fontFamily: 'Teko', fontSize: "14px", color: "#4d4c4c"}}>{product.description}</p>*/}
                        {
                            screenWidth < 577 &&
                            <button onClick={() => removeFromCart(id)} style={buttonRemoveStyleMobile}>Remove</button>
                        }
                    </div>
                    {
                        screenWidth > 576 &&
                        <button onClick={() => removeFromCart(id)} style={buttonRemoveStyle}>Remove</button>
                    }
                </div>
            </td>
            <td className="text-end">
                <p>€{price}</p>
            </td>
        </tr>
    );
}