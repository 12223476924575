import Header from "../header/Header";
import React from "react";
import Footer from "../footer/Footer";
import {Outlet} from "react-router-dom";
import ChangeLanguageButton from "../components/ChangeLanguageButton";

export default function UserApp() {
    return (
        <>
            <Header />
            <Outlet />
            <Footer />
            <ChangeLanguageButton />
        </>
    )
}