import {useLanguage} from "../context/languageContext";
import AnimatedButton from "../components/AnimatedButton";
import ReactMarkdown from "react-markdown";
import {useState} from "react";
import axios from "axios";

export default function Contact(){
    const { strings } = useLanguage();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [comment, setComment] = useState('');
    const talk_tr_style = {
        width: "100%",
        maxWidth: "600px"
    };

    const talk_input_style = {
        width: "100%",
        marginBottom: "8px",
        border: "none",
        backgroundColor: "#dcd2c4",
        padding: "6px",
        borderRadius: "10px"
    }
    const handleClick = () => {
            if (name && email && phone && comment) {
                const fetchData = async () => {
                    try {
                        const response = await axios.post('https://ksartbykristinaandrus.de/api/records', {
                            method: "4",
                            name: name,
                            email: email,
                            phone: phone,
                            comment: comment
                        });
                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }
                    setPhone("");
                    setComment("");
                    setEmail("");
                    setName("");
                };
                fetchData();
            } else {
                alert('Please fill in all fields');
            }
    };
    return (
        <section className="container">
            <div className="row">
                <div className="col-12 col-lg-6">
                    <h4>{strings.commissionWork}</h4>
                    <ReactMarkdown components={{
                        p: ({node, ...props}) => <p style={{ fontFamily: 'Teko, sans-serif', color: "#4d4c4c", fontSize: "15px" }} {...props} />
                    }}>{strings.commissionWorkDescription}</ReactMarkdown>
                    <table style={talk_tr_style}>
                        <tbody>
                            <tr style={talk_tr_style}>
                                <th>
                                    <input type="text" placeholder={strings.nameInput} style={talk_input_style} required value={name}
                                           onChange={(e) => setName(e.target.value)}/>
                                </th>
                                <th>
                                    <input type="text" placeholder={strings.emailInput}style={talk_input_style} required value={email}
                                           onChange={(e) => setEmail(e.target.value)}/>
                                </th>
                            </tr>
                            <tr style={talk_tr_style}>
                                <th colSpan={2}>
                                    <input type="phone" placeholder={strings.phoneInput} style={talk_input_style} required value={phone}
                                           onChange={(e) => setPhone(e.target.value)}/>
                                </th>
                            </tr>
                            <tr style={talk_tr_style}>
                                <th colSpan={2}>
                                    <input type="text" placeholder={strings.commentInput} style={talk_input_style} required value={comment}
                                           onChange={(e) => setComment(e.target.value)}/>
                                </th>
                            </tr>
                            <tr style={talk_tr_style}>
                                <th colSpan={2}>
                                    <AnimatedButton title={strings.send} handleOnClick={() => handleClick()} width="100%"/>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-12 col-lg-6"
                     style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <table width="100%">
                        <tbody>
                        <tr style={{textAlign: 'center'}}>
                            <td style={{paddingRight: 12, paddingTop: 12, textAlign: 'end'}}>
                                <a href="https://mail.google.com/mail/?view=cm&source=mailto&to=ksartabstract@gmail.com" style={{color: "black", textDecoration: "none", display: "inline-block"}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-envelope-at" viewBox="0 0 16 16">
                                        <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z"/>
                                        <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"/>
                                    </svg>
                                </a>
                            </td>
                            <td style={{paddingTop: 12, textAlign: 'start'}}>
                                <a href="https://mail.google.com/mail/?view=cm&source=mailto&to=ksartabstract@gmail.com" style={{color: "black", textDecoration: "none"}}>
                                    {strings.email}
                                </a>
                            </td>
                        </tr>
                            <tr style={{textAlign: 'center'}}>
                                <td style={{paddingRight: 12, paddingTop: 12, textAlign: 'end'}}>
                                    <a href="https://www.instagram.com/kristina_andrus_art?igsh=MTZoZ2tlcjZzY3U2NA==" style={{color: "black", textDecoration: "none"}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                                                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                                            </svg>
                                    </a>
                                    </td>
                                    <td style={{paddingTop: 12, textAlign: 'start'}}>
                                        <a href="https://www.instagram.com/kristina_andrus_art?igsh=MTZoZ2tlcjZzY3U2NA==" style={{color: "black", textDecoration: "none"}}>
                                            {strings.instagram}
                                        </a>
                                    </td>
                            </tr>
                            <tr style={{textAlign: 'center'}}>
                                <td style={{paddingRight: 12, paddingTop: 12, textAlign: 'end'}}>
                                    <a href="https://t.me/kristina_andrus_art" style={{color: "black", textDecoration: "none"}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-telegram" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
                                        </svg>
                                    </a>
                                </td>
                                <td style={{paddingTop: 12, textAlign: 'start'}}>
                                    <a href="https://t.me/kristina_andrus_art" style={{color: "black", textDecoration: "none"}}>
                                        {strings.telegram}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
}
