import {useState} from "react";
import {useLanguage} from "../context/languageContext";

export default function ChangeLanguageButton() {
    const { setLanguage, currentLanguage } = useLanguage();

    const de_flag_svg = ( <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-de" width={24} height={24} viewBox="0 0 512 512">
        <path fill="#ffce00" d="M0 341.3h512V512H0z"/>
        <path fill="#000" d="M0 0h512v170.7H0z"/>
        <path fill="#d00" d="M0 170.7h512v170.6H0z"/>
    </svg> );

    const eng_flag_svg = ( <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-gb" width={24} height={24} viewBox="0 0 512 512">
        <path fill="#012169" d="M0 0h512v512H0z"/>
        <path fill="#FFF" d="M512 0v64L322 256l190 187v69h-67L254 324 68 512H0v-68l186-187L0 74V0h62l192 188L440 0z"/>
        <path fill="#C8102E" d="m184 324 11 34L42 512H0v-3l184-185zm124-12 54 8 150 147v45L308 312zM512 0 320 196l-4-44L466 0h46zM0 1l193 189-59-8L0 49V1z"/>
        <path fill="#FFF" d="M176 0v512h160V0H176zM0 176v160h512V176H0z"/>
        <path fill="#C8102E" d="M0 208v96h512v-96H0zM208 0v512h96V0h-96z"/>
    </svg>);
    const divChangeButtonStyle = {
        backgroundColor: 'rgba(255,255,255, 1)',
        color: '#000000',
        padding: '10px 20px',
        borderRadius: '5px',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        width: 100
    }

    const handleLanguage = (newLanguage) => {
        if (newLanguage === "DE"){
            setLanguage("ENG");
        } else if ( newLanguage === "ENG") {
            setLanguage("DE");
        }
    }
    return (
        <div style={{ position: 'relative', height: "auto" }}>
            <div style={{ position: 'fixed', top: "90%", left: '20px'}}>
                <button style={divChangeButtonStyle} onClick={() => handleLanguage(currentLanguage)}>
                    {currentLanguage === "ENG" ? eng_flag_svg : de_flag_svg} {currentLanguage}
                </button>
            </div>
        </div>
    );
}