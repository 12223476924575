import {useNavigate, useParams} from 'react-router-dom';
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../authorization/AuthContext";
export default function GalleryDetails() {
    const { isAuth } = useContext(AuthContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [imageExists, setImageExists] = useState(true);

    useEffect(() => {
        console.log(`isAuth: ${isAuth}`);
        if (!isAuth) {
            navigate("/");
        }
    }, [isAuth]);
    const handleImageError = () => {
        setImageExists(false);
    };

    const uploadFile = (file) => {
        if (!file) return;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('method', 'upload_gallery_image');
        formData.append('fileName', id);

        fetch('https://ksartbykristinaandrus.de/api/records', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                navigate(`/gallery-item/${data.imageUrl}`)
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    return (
        <div className="container">
            <div className="row">
                <div className="d-flex justify-content-center align-items-center flex-column">
                    {
                        imageExists ? (
                            <div>
                                <p>{"https://ksartbykristinaandrus.de/images/" + id}</p>
                                <img
                                    src={"https://ksartbykristinaandrus.de/images/" + id}
                                    alt="None"
                                    width={300}
                                    onError={handleImageError}
                                    style={{ marginRight: "16px" }}
                                />
                            </div>
                        ) : (
                            <div>
                                <p className="h2">Image is not exist. Try again!</p>
                                <img src="https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExcWFhbmZlaHkwc2VoaDU4cDR0cGwzYTRza2tpNTUwbmJwbTUxNXJvdiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/gJ3mEToTDJn3LT6kCT/giphy.gif" alt="" width={300} style={{ marginRight: "16px" }} />
                            </div>
                        )
                    }
                <div>
                    <input type="file" onChange={handleFileChange} style={{marginTop: "10px"}}/>
                    <button onClick={() => uploadFile(file)}>Save</button>
                </div>
                </div>
            </div>
        </div>
    );
}