import "./buttonCheckout.css";
import axios from 'axios';
import {useContext} from "react";
import {ShopContext} from "../../context/shoppingContext";

export default function ButtonCheckout ()  {
    const {cartItems, products, cartIsEmpty} = useContext(ShopContext);
    const handleSubmit = async () => {
        if (!cartIsEmpty) {
            return
        }
        const itemsInCart = products.filter((product) =>
            cartItems.some((item) => item.id === product.id)
        );
        try {
            const response = await axios.post('https://ksartbykristinaandrus.de//api/records',
                { method: 3, itemsInCart },
                {
                        headers: {
                        'Content-Type': 'application/json'
                    }
                }
                );

            if (response.status === 200) {
                const data = response.data;
                if (data) {
                    window.location.href = data.url;
                } else {
                    console.error('Unexpected response:', data);
                }
            } else {
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };
    return (
        <div className="box-3">
            <div className="btn btn-checkout" onClick={() => handleSubmit()}>
                <span>CHECKOUT</span>
            </div>
        </div>
    );
};
