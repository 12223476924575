import {createContext, useEffect, useState} from "react";

export const ShopContext = createContext(null);

export const ShopContextProvider = (props) => {
    const [products, setProducts] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    const [total, setTotal] = useState(0)

    useEffect(() => {
        countTotal();
    }, [cartItems]);

    const cartIsEmpty = () => {
        return cartItems.length === 0;
    }
    const countTotal = () => {
        let sum = 0;
        // eslint-disable-next-line array-callback-return
        cartItems.map((item) => {
            const product = getProductById(item.id);
            if (product.sale_price) {
                sum += parseFloat(product.sale_price);
            } else {
                sum += parseFloat(product.price);
            }
        });
        setTotal(sum);
    }
    const productsUpdate = (productsList) => {
        setProducts(productsList);
    }
    const getProductById = (id) => {
        const foundProduct = products.find(product => product.id === id);
        return foundProduct || {};
    }
    const itemExistInCart = (itemId) => {
        return cartItems.some((item) => item.id === itemId);
    }
    const addToCart = (itemId) => {
        const isItemInCart = cartItems.some((item) => item.id === itemId);
        if (!isItemInCart) {
            setCartItems((prevItems) => [...prevItems, { id: itemId }]);
        }
    };
    const removeFromCart = (itemId) => {
        setCartItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
    };

    function getUpdatedProducts(new_name, new_price, id) {
        return products.map((product) => {
            // If the product ID matches the provided ID, update its name and price
            if (product.id === id) {
                return {
                    ...product,
                    name: new_name,
                    price: new_price,
                };
            }
            return product; // Return the unchanged product if ID does not match
        }); // Return the updated products array
    };
    const contexValue = {
        cartItems, addToCart, removeFromCart,
        itemExistInCart, products, productsUpdate,
        getProductById, total, cartIsEmpty, getUpdatedProducts
    };
    return <ShopContext.Provider value={contexValue}>{props.children}</ShopContext.Provider>
}
